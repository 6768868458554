import { createSlice } from '@reduxjs/toolkit';

export const controlsSlice = createSlice({
  name: 'controls',
  initialState: {
    loading: false,
    show_menu: false,
    posts: null,
    refreshPosts: false,
    show_modal: false,
    modal_mode: '',
    page_mode: '',
    admin_activity: '',
    home_page_paras: null,
    about_page_paras: null,
    footer_txt_content: {
      id: '',
      ftrTxtOne: 'Footer text one',
      ftrTxtTwo: 'Footer text two',
      ftrTxtThree: 'Footer text three',
      ftrTxtFour: 'Footer text four',
    },

    settings: {
      footer_settings: {
        id: '',
        show_txt_one: true,
        show_txt_two: true,
        show_txt_three: true,
        show_txt_four: true,
        show_facebook_icon: true,
        show_twitter_icon: true,
        show_instagram_icon: true,
        show_linkedin_icon: true,
        show_google_icon: true,
        facebook_link: 'https://www.facebook.com',
        twitter_link: 'https://www.twitter.com',
        instagram_link: 'https://www.instagram.com',
        linkedin_link: 'https://www.linkedin.com',
        google_link: 'https://www.google.com',
      },
      home_page_settings: {},
    },
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setShowMenu: (state, action) => {
      state.show_menu = action.payload;
    },
    setPosts: (state, action) => {
      state.posts = action.payload;
    },
    setRefreshPosts: (state, action) => {
      state.refreshPosts = action.payload;
    },
    setShowModal: (state, action) => {
      state.show_modal = action.payload;
    },
    setModalMode: (state, action) => {
      state.modal_mode = action.payload;
    },
    setPageMode: (state, action) => {
      state.page_mode = action.payload;
    },
    setAdminActivity: (state, action) => {
      state.admin_activity = action.payload;
    },
    setHomePageParas: (state, action) => {
      state.home_page_paras = action.payload;
    },
    setAboutPageParas: (state, action) => {
      state.about_page_paras = action.payload;
    },
    setFooterTxtContent: (state, action) => {
      state.footer_txt_content = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
  },
});

export const {
  setLoading,
  setShowMenu,
  setPosts,
  setRefreshPosts,
  setShowModal,
  setModalMode,
  setPageMode,
  setAdminActivity,
  setHomePageParas,
  setAboutPageParas,
  setFooterTxtContent,
  setSettings,
} = controlsSlice.actions;

export default controlsSlice.reducer;
