import React from 'react';
import { useSelector } from 'react-redux';
import { useFilteredPosts } from '../../hooks/usePosts';
import { useViewPostModal } from '../../hooks/useViewPostModal';
import SideMenu from '../../components/Navigation/SideMenu/SideMenu';
import Footer from '../../components/Footer/Footer';
import ContactCard from '../../components/ContactCard/ContactCard';
import PostWithModal from '../../components/PostWithModal/PostWithModal';

import './dragAndDriveChains.css';
import '../../styles/global.css';

import backgroundImg from '../../assets/images/bannerPlaceholder.jpg';

export default function DragAndDriveChains() {
  const { posts, show_modal, modal_mode } = useSelector(
    (state) => state.controls
  );
  const filteredPosts = useFilteredPosts(posts, 'drag_and_drive_chains');
  const { handleModalToggle, selectedPost, selectedPostId } =
    useViewPostModal();

  return (
    <main className="container-fluid glbl-container">
      <SideMenu />
      <div className="glbl-content-container">
        <h1 className="glbl-h1">Drag Chains & Drive Chains</h1>
        <section className="txt-section">
          <p>
            We have a variety of drag chains & drive chains available. If you
            don't see what you need, please contact us.
          </p>
          <div className="parts-row">
            <div className="parts-contact-container">
              <ContactCard fullWidth={true} />
              <img
                src={backgroundImg}
                alt="construction"
                className="ue-bg-img"
              />
            </div>
          </div>
        </section>
        <section className="parts-section">
          <PostWithModal
            posts={filteredPosts}
            handleModalToggle={handleModalToggle}
            modalMode={modal_mode}
            show_modal={show_modal}
            selectedPost={selectedPost}
            selectedPostId={selectedPostId}
            mode={'drag_and_drive_chains'}
          />
        </section>
      </div>
      <Footer />
    </main>
  );
}
