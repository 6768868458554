import React, { useState, useEffect } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import '../../styles/global.css';
import './contactCard.css';

import { CONTACT_INFO as ci } from '../../assets/textContent/CONTACT_INFO';
import phoneIcon from '../../assets/icons/icon-phone-white.svg';
import emailIcon from '../../assets/icons/icon-mail-white.svg';
import watchIcon from '../../assets/icons/icon-watch-white.svg';
import locationIcon from '../../assets/icons/icon-location-two-white.svg';

export default function ContactCard({ fullWidth }) {
  const [mobileView, setMobileView] = useState(false);
  const winSize = useWindowSize();

  useEffect(() => {
    if (winSize.width < 768) {
      setMobileView(true);
    } else {
    }
  }, [winSize]);

  return (
    <div
      className={
        fullWidth
          ? 'contact-card-container-full-width frosted-bg-light'
          : 'contact-card-container frosted-bg'
      }>
      <div className="contact-card-row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 contact-card-col">
          <div className="contact-card-icon-row">
            <img src={phoneIcon} alt="phone" className="contact-card-icon" />
          </div>
          <div className="contact-card-info-row">
            <span
              className={
                fullWidth
                  ? 'contact-card-txt-bld frosted-dark-bg'
                  : 'contact-card-txt'
              }>
              Dana Campbell:{' '}
              <span
                className={
                  fullWidth
                    ? 'contact-card-txt-sm-bld frosted-dark-bg'
                    : 'contact-card-txt-sm'
                }>
                {ci.phone_dana}
              </span>
            </span>
          </div>
          {/* <div className="contact-card-info-row">
            <span
              className={
                fullWidth
                  ? 'contact-card-txt-bld frosted-dark-bg'
                  : 'contact-card-txt'
              }>
              Charlie Denallo:{' '}
              <span
                className={
                  fullWidth
                    ? 'contact-card-txt-sm-bld frosted-dark-bg'
                    : 'contact-card-txt-sm'
                }>
                {ci.phone_charlie}
              </span>
            </span>
          </div> */}

          <div className="contact-card-icon-row">
            <img src={emailIcon} alt="email" className="contact-card-icon" />
          </div>
          <div className="contact-card-info-row">
            <span
              className={
                fullWidth
                  ? 'contact-card-txt-bld frosted-dark-bg'
                  : 'contact-card-txt'
              }>
              General:{' '}
              <a
                href={`mailto:${ci.email_general}`}
                className={
                  fullWidth
                    ? 'contact-card-anchor-bld frosted-dark-bg'
                    : 'contact-card-anchor'
                }>
                {ci.email_general}
              </a>
            </span>
          </div>
        </div>
        {!mobileView && (
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 contact-card-col">
            <div className="contact-card-icon-row">
              <img src={watchIcon} alt="watch" className="contact-card-icon" />
            </div>

            <div className="contact-card-info-row">
              <span
                className={
                  fullWidth ? 'contact-card-txt-bld' : 'contact-card-txt'
                }>
                Shop Hours:{' '}
                <span
                  className={
                    fullWidth
                      ? 'contact-card-txt-sm-bld'
                      : 'contact-card-txt-sm'
                  }>
                  {ci.shop_hours}
                </span>
              </span>
            </div>

            <div className="contact-card-icon-row">
              <img
                src={locationIcon}
                alt="location"
                className="contact-card-icon"
              />
            </div>

            <div className="contact-card-info-row">
              <ul className="contact-card-ul">
                <li className="contact-card-li">{ci.address_one}</li>
                <li className="contact-card-li">{ci.address_two}</li>
                <li className="contact-card-li">{ci.address_three}</li>
                <li className="contact-card-li">{ci.address_four}</li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
