import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setRefreshPosts } from '../../redux/controls';
import { Form, Button } from 'react-bootstrap';
import AlertClass from '../../classes/AlertClass';
import FirebaseClass from '../../classes/FirebaseClass';

export default function FooterTextForm() {
  const { loading, footer_txt_content } = useSelector(
    (state) => state.controls
  );
  const [footerTextContent, setFooterTextContent] = useState(null);
  const [txtContent, setTxtContent] = useState('');
  const [footerTxt, setFooterTxt] = useState('');
  const dispatch = useDispatch();
  const ac = new AlertClass();
  const fb = new FirebaseClass();
  const formTxtRef = useRef(null);

  // useEffect(() => {
  //   const { id } = footer_txt_content;
  //   const newFooterTextContent = {};
  //   newFooterTextContent.id = id;

  //   switch (footerTxt) {
  //     case 'footer_txt_one':
  //       newFooterTextContent.ftrTxtOne = txtContent;
  //       break;
  //     case 'footer_txt_two':
  //       newFooterTextContent.ftrTxtTwo = txtContent;
  //       break;
  //     case 'footer_txt_three':
  //       newFooterTextContent.ftrTxtThree = txtContent;
  //       break;
  //     case 'footer_txt_four':
  //       newFooterTextContent.ftrTxtFour = txtContent;
  //       break;
  //     default:
  //   }

  //   let oldFooterTextContent = footer_txt_content;
  //   let mergedContent = { ...oldFooterTextContent, ...newFooterTextContent };
  //   // console.log('mergedContent: ', mergedContent);

  //   // console.log('newFooterTextContent: ', newFooterTextContent);

  //   setFooterTextContent(mergedContent);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [txtContent, footerTxt]);
  // console.log('ORIGINAL footer_txt_content: ', footer_txt_content);
  // console.log('UPDATED footerTextContent: ', footerTextContent);

  async function makePost(title, txtContent, images, page, pageParag, txtOnly) {
    try {
      dispatch(setLoading(true));
      await fb.makePost(title, txtContent, images, page, pageParag, txtOnly);
      dispatch(setLoading(false));
      ac.successAlert('Post submitted!');
      dispatch(setRefreshPosts(true));
    } catch (error) {
      ac.errorAlert('Error submitting post', error);
    }
  }

  async function handleTextSubmit(e) {
    e.preventDefault();
    const location = 'Footer';
    let txt;
    const txtOnly = true;
    const txtContent = formTxtRef.current['content'].value;
    const footerTxt = formTxtRef.current['footer-txt'].value;

    switch (footerTxt) {
      case 'footer_txt_one':
        txt = 'footer text one';
        break;
      case 'footer_txt_two':
        txt = 'footer text two';
        break;
      case 'footer_txt_three':
        txt = 'footer text three';
        break;
      case 'footer_txt_four':
        txt = 'footer text four';
        break;
      default:
        break;
    }

    // console.log('txtContent: ', txtContent);
    // console.log('footerTxt: ', footerTxt);

    const title = `${location}: ${txt}`;
    // console.log('title: ', title);

    makePost(title, txtContent, null, location, footerTxt, txtOnly);
    formTxtRef.current.reset();
  }

  // console.log('footer_txt_content: ', footer_txt_content);
  // console.log('formTxtRef: ', formTxtRef);

  return (
    <Form className="footer-form" ref={formTxtRef}>
      <Form.Group className="mb-3" controlId="content">
        <Form.Control
          as="textarea"
          rows="4"
          placeholder="Content"
          onChange={(e) => setTxtContent(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="footer-txt">
        <Form.Control
          as="select"
          aria-label="Default select example"
          required
          onChange={(e) => setFooterTxt(e.target.value)}>
          <option>Which Footer text do you want to add?</option>
          <option value="" disabled="disabled">
            ─────────────────────────
          </option>

          <option value="footer_txt_one">Footer: text one</option>
          <option value="footer_txt_two">Footer: text two</option>
          <option value="footer_txt_three">Footer: text three</option>
          <option value="footer_txt_four">Footer: text four</option>
        </Form.Control>
      </Form.Group>

      <div className="upload-button-wrapper">
        <Button
          className="upload-submit-button"
          type="submit"
          disabled={loading}
          onClick={handleTextSubmit}>
          Submit
        </Button>
      </div>
    </Form>
  );
}
