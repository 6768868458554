import { useState, useEffect } from 'react';

export const useFilteredPosts = (posts, tag) => {
  const [filteredPosts, setFilteredPosts] = useState(null);

  useEffect(() => {
    if (posts) {
      const filtered = posts.filter(
        (post) => post.only_text === false && post.tag === tag
      );
      setFilteredPosts(filtered);
    }
  }, [posts, tag]);

  return filteredPosts;
};
