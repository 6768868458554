import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SideMenu from '../../components/Navigation/SideMenu/SideMenu';
import Footer from '../../components/Footer/Footer';

import './about.css';
import '../../styles/global.css';

import { ABOUT_PAGE as defaultCont } from '../../assets/textContent/ABOUT_PAGE';
import petersonGrinder from '../../assets/images/peterson-grinder.jpeg';

export default function About() {
  const { about_page_paras } = useSelector((state) => state.controls);
  const [paraOne, setParaOne] = useState(defaultCont.paraOne);

  useEffect(() => {
    if (about_page_paras) {
      about_page_paras.forEach((item) => {
        if (item.page_parag === 'about_parag_one') {
          setParaOne(item.txt_cont);
        }
      });
    }
  }, [about_page_paras]);

  return (
    <div className="container-fluid glbl-container">
      <SideMenu />
      <div className="glbl-content-container">
        <div className="row">
          <h1 className="glbl-h1">About</h1>
        </div>
        <div className="row about-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <img
              src={petersonGrinder}
              alt="peterson grinder"
              className="about-img bx-shdw"
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <p className="about-text">{paraOne}</p>
            <p className="about-text">{defaultCont.paraTwo}</p>
            <p className="about-text">{defaultCont.paraThree}</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
