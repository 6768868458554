import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Home from '../pages/HomePage/Home';
import Admin from '../pages/AdminPage/Admin';
import About from '../pages/AboutPage/About';
import Contact from '../pages/ContactPage/Contact';
import Login from '../pages/LoginPage/Login';
import Signup from '../pages/SignupPage/Signup';
import ProductsServices from '../pages/ProductsServices/ProductsServices';
import UsedEquipment from '../pages/UsedEquipment/UsedEquipment';
import IndustrialParts from '../pages/IndustrialParts/IndustrialParts';
import GrinderParts from '../pages/GrinderParts/GrinderParts';
import ClutchParts from '../pages/ClutchParts/ClutchParts';
import ConveyorAndDriveBelts from '../pages/ConveyorAndDriveBelts/ConveyorAndDriveBelts';
import DragAndDriveChains from '../pages/DragAndDriveChains/DragAndDriveChains';

export default function NavRoutes() {
  return (
    <Routes>
      <Route
        path="/admin"
        element={<ProtectedRoute>{<Admin />}</ProtectedRoute>}
      />
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/products-services" element={<ProductsServices />} />
      <Route path="/used-equipment" element={<UsedEquipment />} />
      <Route path="/industrial-parts" element={<IndustrialParts />} />
      <Route path="/grinder-parts" element={<GrinderParts />} />
      <Route path="/clutch-parts" element={<ClutchParts />} />
      <Route
        path="/conveyor-and-drive-belts"
        element={<ConveyorAndDriveBelts />}
      />
      <Route path="/drag-and-drive-chains" element={<DragAndDriveChains />} />
    </Routes>
  );
}
