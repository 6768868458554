export const CONTACT_INFO = {
  phone_dana: '216-402-2628',
  phone_charlie: '615-815-4455',
  email_dana: 'dana@email.com',
  email_charlie: 'charlie@email.com',
  email_general: 'info@ericharinc.com',
  email_dana_grinder_parts: 'dana@grinderpartswarehouse.com',
  address_one: '15151 York Rd.',
  address_two: 'Warehouse 1',
  address_three: 'N. Royalton, Ohio 44133',
  shop_hours: 'Mon - Sat: 9am - 6pm',
  parts_and_service: '24/7',
};
