import React from 'react';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import './loadingSpinner.css';

export default function LoadingSpinner() {
  const { loading } = useSelector((state) => state.controls);

  return (
    <div className="loading-spinner-container">
      <ClipLoader
        color="#2198D5"
        loading={loading}
        size={80}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}
