import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './footer.css';
import '../../styles/global.css';

import facebookIcon from '../../assets/icons/facebook-icon.svg';
import twitterIcon from '../../assets/icons/twitter-icon.svg';
import instagramIcon from '../../assets/icons/instagram-icon.svg';
import linkedinIcon from '../../assets/icons/linkedin-icon.svg';
import googleIcon from '../../assets/icons/google-icon.svg';
import logo from '../../assets/images/erichar-logo.png';
import womanOwnedBusinessImg from '../../assets/images/certified-women-owned.jpg';
import ohioForestryImg from '../../assets/images/the-ohio-forestry-assoc.jpg';
import compostingCouncilImg from '../../assets/images/us-composting-council.jpg';
import phoneIcon from '../../assets/icons/icon-phone-white.svg';
import emailIcon from '../../assets/icons/icon-mail-white.svg';

import { CONTACT_INFO as ci } from '../../assets/textContent/CONTACT_INFO';

export default function Footer() {
  const { footer_txt_content, settings } = useSelector(
    (state) => state.controls
  );
  const [fTxtOne, setFTxtOne] = useState('');
  const [fTxtTwo, setFTxtTwo] = useState('');
  const [fTxtThree, setFTxtThree] = useState('');
  const [fTxtFour, setFTxtFour] = useState('');
  const [showTxtOne, setShowTxtOne] = useState(true);
  const [showTxtTwo, setShowTxtTwo] = useState(true);
  const [showFooterColOne, setShowFooterColOne] = useState(true);
  const [showTxtThree, setShowTxtThree] = useState(true);
  const [showTxtFour, setShowTxtFour] = useState(true);
  const [showFooterColTwo, setShowFooterColTwo] = useState(true);
  const [facebookLink, setFacebookLink] = useState('');
  const [twitterLink, setTwitterLink] = useState('');
  const [instagramLink, setInstagramLink] = useState('');
  const [linkedinLink, setLinkedinLink] = useState('');
  const [googleLink, setGoogleLink] = useState('');
  const [showFacebookIcon, setShowFacebookIcon] = useState(true);
  const [showTwitterIcon, setShowTwitterIcon] = useState(true);
  const [showInstagramIcon, setShowInstagramIcon] = useState(true);
  const [showLinkedinIcon, setShowLinkedinIcon] = useState(true);
  const [showGoogleIcon, setShowGoogleIcon] = useState(true);

  useEffect(() => {
    const { footer_settings } = settings;

    setShowTxtOne(footer_settings.show_txt_one);
    setShowTxtTwo(footer_settings.show_txt_two);
    setShowTxtThree(footer_settings.show_txt_three);
    setShowTxtFour(footer_settings.show_txt_four);
    setShowFacebookIcon(footer_settings.show_facebook_icon);
    setShowTwitterIcon(footer_settings.show_twitter_icon);
    setShowInstagramIcon(footer_settings.show_instagram_icon);
    setShowLinkedinIcon(footer_settings.show_linkedin_icon);
    setShowGoogleIcon(footer_settings.show_google_icon);
    setFacebookLink(footer_settings.facebook_link);
    setTwitterLink(footer_settings.twitter_link);
    setInstagramLink(footer_settings.instagram_link);
    setLinkedinLink(footer_settings.linkedin_link);
    setGoogleLink(footer_settings.google_link);

    if (
      footer_settings.show_txt_one === false &&
      footer_settings.show_txt_two === false
    ) {
      setShowFooterColOne(false);
    }
    if (
      footer_settings.show_txt_three === false &&
      footer_settings.show_txt_four === false
    ) {
      setShowFooterColTwo(false);
    }

    setFTxtOne(footer_txt_content.ftrTxtOne);
    setFTxtTwo(footer_txt_content.ftrTxtTwo);
    setFTxtThree(footer_txt_content.ftrTxtThree);
    setFTxtFour(footer_txt_content.ftrTxtFour);
  }, [footer_txt_content, settings]);

  return (
    <footer className="container-fluid footer-cont">
      <div className="row footer-row">
        <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 footer-col">
          <div className="footer-contact-section">
            <div className="footer-row">
              <div className="col-12 footer-col">
                <div className="footer-logo-container">
                  <img src={logo} alt="logo" className="footer-logo" />
                </div>
              </div>
            </div>
            <div className="footer-contact-icon-row">
              <img
                src={phoneIcon}
                alt="phone"
                className="footer-contact-icon"
              />
              <span className="footer-contact-txt">{ci.phone_dana}</span>
            </div>

            <div className="footer-contact-icon-row">
              <img
                src={emailIcon}
                alt="email"
                className="footer-contact-icon"
              />
              <span className="footer-contact-txt">{ci.email_general}</span>
            </div>
            <div className="footer-contact-icon-row">
              <img
                src={emailIcon}
                alt="email"
                className="footer-contact-icon"
              />
              <span className="footer-contact-txt-sm">
                {ci.email_dana_grinder_parts}
              </span>
            </div>
          </div>
        </div>
        {showFooterColOne && (
          <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 footer-col">
            <div className="row ft-rw-one">
              {showTxtOne && <span className="footer-txt">{fTxtOne}</span>}
            </div>
            <div className="row ft-rw-two">
              {showTxtTwo && <span className="footer-txt">{fTxtTwo}</span>}
            </div>
          </div>
        )}
        {showFooterColTwo && (
          <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 footer-col">
            <div className="row ft-rw-three">
              {showTxtThree && <span className="footer-txt">{fTxtThree}</span>}
            </div>
            <div className="row ft-rw-four">
              {showTxtFour && <span className="footer-txt">{fTxtFour}</span>}
            </div>
          </div>
        )}
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 footer-col">
          <div className="footer-img-container">
            <ul className="footer-ul">
              <li className="footer-li">
                <img
                  src={womanOwnedBusinessImg}
                  alt="certified woman owned business"
                  className="footer-img"
                />
              </li>
              <li className="footer-li">
                <img
                  src={compostingCouncilImg}
                  alt="composting council"
                  className="footer-img"
                />
              </li>
              <li className="footer-li">
                <img
                  src={ohioForestryImg}
                  alt="ohio forestry association"
                  className="footer-img"
                />
              </li>
            </ul>
          </div>
          <div className="footer-icon-container">
            {showFacebookIcon && (
              <a href={facebookLink} target="_blank" rel="noopener noreferrer">
                <img
                  src={facebookIcon}
                  alt="facebook"
                  className="footer-icon"
                />
              </a>
            )}
            {showTwitterIcon && (
              <a href={twitterLink} target="_blank" rel="noopener noreferrer">
                <img src={twitterIcon} alt="twitter" className="footer-icon" />
              </a>
            )}
            {showInstagramIcon && (
              <a href={instagramLink} target="_blank" rel="noopener noreferrer">
                <img
                  src={instagramIcon}
                  alt="instagram"
                  className="footer-icon"
                />
              </a>
            )}
            {showLinkedinIcon && (
              <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
                <img
                  src={linkedinIcon}
                  alt="linkedin"
                  className="footer-icon"
                />
              </a>
            )}
            {showGoogleIcon && (
              <a href={googleLink} target="_blank" rel="noopener noreferrer">
                <img src={googleIcon} alt="google" className="footer-icon" />
              </a>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}
