import React from 'react';
import { useNavigate } from 'react-router-dom';
import SideMenu from '../../components/Navigation/SideMenu/SideMenu';
import Footer from '../../components/Footer/Footer';

import './productsServices.css';
import '../../styles/global.css';

import { LINKS as links } from '../../assets/links/LINKS';
import usedEquipmentImg from '../../assets/images/boxOnePlaceholder.jpg';
import grinderPartsImg from '../../assets/images/grinder-parts.jpg';
import industrialPartsImg from '../../assets/images/industrial-parts.jpg';
import clutchPartsImg from '../../assets/images/clutch-part.png';
import conveyorBeltsImg from '../../assets/images/conveyor-belts.jpg';
import dragDriveChainsImg from '../../assets/images/drag-chain.jpg';

export default function ProductsServices() {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <main className="container-fluid glbl-container">
      <SideMenu />
      <div className="glbl-content-container">
        <h1 className="glbl-h1">Products & Services</h1>

        <div className="row ps-row mt-twenty">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-flex-start">
            <ul className="glbl-ul">
              <li className="glbl-bold">Services</li>
              <li className="ml-10">Equipment Inspections (US & Canada)</li>
              <li className="ml-10">Repair Estimates</li>
              <li className="ml-10">Insurance Estimates</li>
              <li className="ml-10">In-Shop Service & Rebuilds</li>
              <li className="ml-10">Onsite Service</li>
              <li className="ml-10">Safety & Maintenance Instruction</li>
            </ul>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-flex-end">
            <span>
              Service & Parts includes Chippers, Grinders, Screeners, Trommels,
              Stackers, Conveyors
            </span>
            <br />
            <br />
            <span>
              Morbark, Bandit, Diamond Z, Peterson, Precision Husky, Olathe,
              Duratech, McCloskey, Terex, Rotochopper, Vermeer, and more.
            </span>
          </div>
        </div>

        <div className="row ps-row mt-twenty">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 ps-col">
            <div
              className="ps-img-container"
              onClick={() => navigateTo(links.used_equipment)}>
              <img
                src={usedEquipmentImg}
                alt="used equipment"
                className="ps-img"
              />
              <div className="ps-txt-overlay">
                <h2 className="ps-txt">Used Equipment</h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 ps-col">
            <div
              className="ps-img-container"
              onClick={() => navigateTo(links.grinder_parts)}>
              <img
                src={grinderPartsImg}
                alt="grinder parts"
                className="ps-img"
              />
              <div className="ps-txt-overlay">
                <h2 className="ps-txt">New/Used Grinder & Chipper Parts</h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 ps-col">
            <div
              className="ps-img-container"
              onClick={() => navigateTo(links.industrial_parts)}>
              <img
                src={industrialPartsImg}
                alt="industrial parts"
                className="ps-img"
              />
              <div className="ps-txt-overlay">
                <h2 className="ps-txt">Industrial Parts</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="row ps-row mt-twenty">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 ps-col">
            <div
              className="ps-img-container"
              onClick={() => navigateTo(links.clutch_parts)}>
              <img src={clutchPartsImg} alt="clutch parts" className="ps-img" />
              <div className="ps-txt-overlay">
                <h2 className="ps-txt">Clutch Parts</h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 ps-col">
            <div
              className="ps-img-container"
              onClick={() => navigateTo(links.conveyor_and_drive_belts)}>
              <img
                src={conveyorBeltsImg}
                alt="conveyor and drive belts"
                className="ps-img"
              />
              <div className="ps-txt-overlay">
                <h2 className="ps-txt">Conveyor & Drive Belts</h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 ps-col">
            <div
              className="ps-img-container"
              onClick={() => navigateTo(links.drag_and_drive_chains)}>
              <img
                src={dragDriveChainsImg}
                alt="drag and drive chains"
                className="ps-img"
              />
              <div className="ps-txt-overlay">
                <h2 className="ps-txt">Drag Chain & Drive Chain</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
