import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setRefreshPosts } from '../../redux/controls';
import { Form, Button } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import AlertClass from '../../classes/AlertClass';
import FirebaseClass from '../../classes/FirebaseClass';
import 'react-toastify/dist/ReactToastify.css';
import './uploadForm.css';

export default function UploadForm({ tag }) {
  const { loading } = useSelector((state) => state.controls);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const ac = new AlertClass();
  const fb = new FirebaseClass();
  const formRef = useRef(null);

  const findFormErrors = () => {
    const newErrors = {};
    if (formRef.current['title'].value === '') {
      newErrors.title = 'This field is required.';
    }
    if (formRef.current['price'].value === '') {
      newErrors.price = 'This field is required.';
    }
    return newErrors;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        const title = formRef.current['title'].value;
        const txtContent = formRef.current['content'].value;
        const images = formRef.current['file-input'].files;
        const page = 'prod_serv_page';
        const price = formRef.current['price'].value.replace(/[^0-9.]/g, '');
        dispatch(setLoading(true));
        await fb.makePost(
          title,
          txtContent,
          images,
          page,
          null,
          false,
          false,
          price,
          tag
        );
        dispatch(setLoading(false));
        ac.successAlert('Post submitted!');
        dispatch(setRefreshPosts(true));
      } catch (error) {
        ac.errorAlert('Error submitting post', error);
      }
    }
    formRef.current.reset();
  }

  return (
    <div className="upload-form-container">
      <Form className="upload-form" ref={formRef}>
        <Form.Group className="mb-3" controlId="title">
          <Form.Control
            type="text"
            placeholder="Title"
            required
            isInvalid={!!errors.title}
            errors={errors.title}
          />
        </Form.Group>
        <Form.Control.Feedback type="invalid">
          Please add a title.
        </Form.Control.Feedback>

        <Form.Group className="mb-3" controlId="price">
          <Form.Control
            type="text"
            placeholder="$0"
            required
            isInvalid={!!errors.price}
            errors={errors.price}
          />
        </Form.Group>
        <Form.Control.Feedback type="invalid">
          Please add a price.
        </Form.Control.Feedback>

        <input
          id="file-input"
          type="file"
          multiple
          placeholder="Choose image file(s)"
          className="form-control file-selection-input"
        />

        <Form.Group className="mb-3" controlId="content">
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="Content/Description"
          />
        </Form.Group>

        <div className="upload-button-wrapper">
          <Button
            className="upload-submit-button"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Form>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}
