import React from 'react';
import { useNavigate } from 'react-router-dom';
import './itemListMenu.css';
import '../../styles/global.css';

export default function ItemListMenu({ items }) {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className="ilm-cont">
      <ul className="ilm-ul">
        {items.map((item, index) => {
          return (
            <li
              className="ilm-li"
              key={index}
              onClick={() => navigateTo(item.link)}>
              {item.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
