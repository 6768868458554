import React from 'react';
import ContactForm from '../../components/ContactForm/ContactForm';
import SideMenu from '../../components/Navigation/SideMenu/SideMenu';
import Footer from '../../components/Footer/Footer';

import './contact.css';
import '../../styles/global.css';

import banditGrinderImg from '../../assets/images/bandit-grinder.jpeg';

export default function Contact() {
  return (
    <main className="container-fluid glbl-container">
      <SideMenu />
      <div className="glbl-content-container">
        <div className="row">
          <h1 className="glbl-h1">Contact</h1>
        </div>
        <div className="row contact-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 contact-col">
            <div className="contact-img-container">
              <img
                src={banditGrinderImg}
                alt="bandit grinder"
                className="contact-img"
              />
            </div>
            <div className="contact-page-form-container">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
