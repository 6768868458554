import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signOut, getAuth } from 'firebase/auth';
import { setUserAuth, setCurrentUser } from '../../../redux/user';
import { setShowMenu } from '../../../redux/controls';
import './sideMenu.css';

import { LINKS as links } from '../../../assets/links/LINKS';
import closeIcon from '../../../assets/icons/icon-close-circle.svg';
import menuIcon from '../../../assets/icons/icon-menu.svg';
import contactIcon from '../../../assets/icons/contacts.svg';
import aboutIcon from '../../../assets/icons/information.svg';
import productsIcon from '../../../assets/icons/arrow-right-circle.svg';
import chevronDown from '../../../assets/icons/chevron-down-white.svg';
import loginIcon from '../../../assets/icons/log-in.svg';
import logoutIcon from '../../../assets/icons/log-out.svg';
// import signupIcon from '../../../assets/icons/signup.svg';
import lockIcon from '../../../assets/icons/lock.svg';
import tagIcon from '../../../assets/icons/tag.svg';
import logo from '../../../assets/images/erichar-logo.png';

export default function SideMenu() {
  const dispatch = useDispatch();
  const { show_menu } = useSelector((state) => state.controls);
  const { current_user, isAuth } = useSelector((state) => state.user);
  const [linkGroup, setLinkGroup] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  const handleSignout = async () => {
    await signOut(auth)
      .then(() => {
        alert('Signout successful');
      })
      .then(dispatch(setCurrentUser(null)))
      .then(dispatch(setUserAuth(false)))
      .catch((error) => {
        console.log('signout error:', error);
      });
  };

  const toggleMenu = () => {
    dispatch(setShowMenu(!show_menu));
  };

  const handleSetLinkGroup = (e) => {
    if (linkGroup === null || linkGroup !== e.currentTarget.id) {
      setLinkGroup(e.currentTarget.id);
    } else {
      setLinkGroup(null);
    }
  };

  return (
    <div
      className={
        show_menu
          ? 'side-menu-container-open animate-open'
          : 'side-menu-container-closed'
      }>
      <div
        className={
          show_menu
            ? 'side-menu-toggle-controls-open'
            : 'side-menu-toggle-controls-closed'
        }>
        {show_menu ? (
          <img
            src={closeIcon}
            alt="close button"
            className="side-menu-toggle-icon close-icon"
            onClick={toggleMenu}
          />
        ) : (
          <div className="side-menu-closed-wrapper">
            <div className="side-menu-closed-left">
              <img
                src={menuIcon}
                alt="menu button"
                className="side-menu-toggle-icon open-icon"
                onClick={toggleMenu}
              />
            </div>
            <div className="side-menu-closed-right">
              <img
                src={logo}
                alt="logo"
                className="side-menu-closed-logo clickable"
                onClick={() => navigateTo(links.home)}
              />

              {/* {totalSurveys > 0 && current_user != null && (
                <div className="side-menu-new-apps-info-wrapper">
                  <span className="side-menu-text">Surveys:</span>
                  <a href="/admin" className="side-menu-admin-link">
                    <div className="side-menu-new-surveys-count-wrapper">
                      <span className="dark-text">{totalSurveys}</span>
                    </div>
                  </a>
                </div>
              )} */}
            </div>
          </div>
        )}
      </div>
      {show_menu && (
        <div className="side-menu-links-container">
          <a href={links.about} className="side-menu-solo-link">
            About
            <img
              src={aboutIcon}
              alt="about"
              className="side-menu-icon open-icon"
            />
          </a>

          <a href={links.contact} className="side-menu-solo-link">
            Contact
            <img
              src={contactIcon}
              alt="contact"
              className="side-menu-icon open-icon"
            />
          </a>

          {!current_user && (
            <a href={links.login} className="side-menu-solo-link">
              Login
              <img
                src={loginIcon}
                alt="login"
                className="side-menu-icon open-icon"
              />
            </a>
          )}

          <a href={links.products_services} className="side-menu-solo-link">
            Products & Services
            <img
              src={tagIcon}
              alt="products-services"
              className="side-menu-icon open-icon"
            />
          </a>

          <a href={links.used_equipment} className="side-menu-solo-link">
            Used Equipment
            <img
              src={tagIcon}
              alt="products-services"
              className="side-menu-icon open-icon"
            />
          </a>

          <div
            className="side-menu-link-and-icon-wrapper"
            id="01"
            onClick={handleSetLinkGroup}>
            <div className="link-side">
              <span className="side-menu-link-span">Parts</span>
            </div>
            <div className="icon-side">
              <img
                src={chevronDown}
                alt="chevron-down"
                className="side-menu-icon open-icon"
              />
            </div>
          </div>

          {linkGroup === '01' && (
            <div className="sub-link-group-wrapper">
              <a
                href={links.grinder_parts}
                className="side-menu-link side-menu-sub-link">
                Grinder Parts
                <img
                  src={productsIcon}
                  alt="products"
                  className="side-menu-icon open-icon"
                />
              </a>

              <a
                href={links.industrial_parts}
                className="side-menu-link side-menu-sub-link">
                Industrial Parts
                <img
                  src={productsIcon}
                  alt="products"
                  className="side-menu-icon open-icon"
                />
              </a>

              <a
                href={links.clutch_parts}
                className="side-menu-link side-menu-sub-link">
                Clutch Parts
                <img
                  src={productsIcon}
                  alt="products"
                  className="side-menu-icon open-icon"
                />
              </a>

              <a
                href={links.conveyor_and_drive_belts}
                className="side-menu-link side-menu-sub-link">
                Conveyor & Drive Belts
                <img
                  src={productsIcon}
                  alt="products"
                  className="side-menu-icon open-icon"
                />
              </a>

              <a
                href={links.drag_and_drive_chains}
                className="side-menu-link side-menu-sub-link">
                Drag Chains & Drive Chains
                <img
                  src={productsIcon}
                  alt="products"
                  className="side-menu-icon open-icon"
                />
              </a>
            </div>
          )}

          {/* <div className="side-menu-link-and-icon-wrapper">
            <div className="link-side">
              <a href={links.no_link_available} className="side-menu-link">
                Open Sub-Links Two
              </a>
            </div>
            <div className="icon-side" id="02" onClick={handleSetLinkGroup}>
              <img
                src={chevronDown}
                alt="chevron-down"
                className="side-menu-icon open-icon"
              />
            </div>
          </div>

          {linkGroup === '02' && (
            <div className="sub-link-group-wrapper">
              <a
                href={links.no_link_available}
                className="side-menu-link side-menu-sub-link">
                Sub-Link Two A
                <img
                  src={productsIcon}
                  alt="products"
                  className="side-menu-icon open-icon"
                />
              </a>

              <a
                href={links.no_link_available}
                className="side-menu-link side-menu-sub-link">
                Sub-Link Two B
                <img
                  src={productsIcon}
                  alt="products"
                  className="side-menu-icon open-icon"
                />
              </a>
            </div>
          )} */}

          {current_user && isAuth && (
            <a href={links.admin} className="side-menu-solo-link">
              Admin
              <img
                src={lockIcon}
                alt="lock"
                className="side-menu-icon open-icon"
              />
            </a>
          )}

          {current_user && isAuth && (
            <span className="side-menu-solo-link" onClick={handleSignout}>
              Sign Out
              <img
                src={logoutIcon}
                alt="log-out"
                className="side-menu-icon open-icon"
              />
            </span>
          )}

          <div className="side-menu-logo-wrapper">
            <a href="/" className="side-menu-logo-link">
              <img src={logo} alt="logo" className="side-menu-logo" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
