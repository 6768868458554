import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowModal, setModalMode } from '../redux/controls';
import GlobalClass from '../classes/GlobalClass';

export const useViewPostModal = () => {
  const { posts, show_modal } = useSelector((state) => state.controls);
  const dispatch = useDispatch();
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const gc = new GlobalClass();

  const handleModalToggle = (modalMode, postId) => {
    dispatch(setShowModal(!show_modal));
    dispatch(setModalMode(modalMode));
    if (postId) {
      setSelectedPostId(postId);
      setSelectedPost(gc.selectPost(posts, postId));
    }
  };

  return { handleModalToggle, selectedPostId, selectedPost };
};
