import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
import ContactCard from '../ContactCard/ContactCard';
import '../../styles/global.css';
import './banner.css';

import backgroundImg from '../../assets/images/bannerPlaceholder.jpg';
import usedEquipmentImg from '../../assets/images/boxOnePlaceholder.jpg';
import grinderPartsImg from '../../assets/images/grinder-parts.jpg';
import industrialPartsImg from '../../assets/images/industrial-parts.jpg';

export default function Banner({
  titleOne,
  titleTwo,
  titleThree,
  linkOne,
  linkTwo,
  linkThree,
}) {
  const [mobileView, setMobileView] = useState(false);
  const imageArray = [
    { img: usedEquipmentImg, txt: titleOne, link: linkOne },
    { img: grinderPartsImg, txt: titleTwo, link: linkTwo },
    { img: industrialPartsImg, txt: titleThree, link: linkThree },
  ];

  const navigate = useNavigate();
  const winSize = useWindowSize();

  const navigateTo = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (winSize.width < 768) {
      setMobileView(true);
    } else {
    }
  }, [winSize]);

  const MainSection = () => {
    return (
      <div className="container-fluid banner-cont">
        <div className="banner-img-cont">
          <div className="banner-txt-overlay">
            <div className="banner-txt-wrapper">
              <h1 className="banner-h1">Erichar Inc.</h1>
              <h2 className="banner-h2">Wood Recycling Parts Inc.</h2>
              <h2 className="banner-h2">Grinder Parts Warehouse</h2>
            </div>
          </div>
          <img src={backgroundImg} alt="construction" className="banner-img" />
          {!mobileView && <ContactCard />}
        </div>

        <div className="banner-sub-area">
          {imageArray &&
            imageArray.map((image, index) => {
              return (
                <SubSection
                  img={image.img}
                  text={image.txt}
                  link={image.link}
                  key={index}
                />
              );
            })}
        </div>
      </div>
    );
  };

  const SubSection = ({ img, index, text, link }) => {
    return (
      <div className="banner-sub-cont bx-shdw" onClick={() => navigateTo(link)}>
        <div className="banner-sub-img-cont">
          <div className="banner-sub-txt-overlay">
            <span className="banner-sub-txt">{text}</span>
          </div>
          <img
            src={img}
            key={index}
            alt="construction"
            className="banner-sub-img"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <MainSection />
    </>
  );
}
