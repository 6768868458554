import React, { useState, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

import './contactForm.css';

export default function ContactForm() {
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const emailJSconfig = {
    service_id: 'gen_contact',
    template_id: 'gen_contact',
  };

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        emailJSconfig.service_id,
        emailJSconfig.template_id,
        formRef.current,
        'Z0btwz3GJIiTH9jKL'
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('Thank you for your message.');
          formRef.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
    setLoading(false);
  }

  return (
    <div className="contact-frm-container">
      <Form ref={formRef} className="contact-frm">
        <Form.Group controlId="name" className="mb-3">
          <Form.Control
            name="name"
            type="text"
            placeholder="Enter name"
            required
          />
        </Form.Group>

        <Form.Group controlId="email" className="mb-3">
          <Form.Control
            name="email"
            type="email"
            placeholder="Enter email"
            required
          />
        </Form.Group>

        <Form.Group controlId="phone" className="mb-3">
          <Form.Control
            name="phone"
            type="tel"
            placeholder="Enter phone number"
            required
          />
        </Form.Group>

        <Form.Group controlId="message" className="mb-3">
          <Form.Control
            as="textarea"
            rows="3"
            placeholder="Enter message"
            required
            name="message"
          />
        </Form.Group>
        <div className="contact-form-button-wrapper">
          <Button
            className="contact-submit-button btn-warning"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
}
