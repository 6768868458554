import React from 'react';
import SideMenu from '../../components/Navigation/SideMenu/SideMenu';
import AdminControlAndView from '../../components/AdminControlAndView/AdminControlAndView';

import './admin.css';
import '../../styles/global.css';

export default function Admin() {
  return (
    <div className="container-fluid glbl-container">
      <SideMenu />
      <div className="glbl-content-container">
        <div className="row">
          <h1 className="glbl-h1">Admin</h1>
        </div>
        <AdminControlAndView />
      </div>
    </div>
  );
}
