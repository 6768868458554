import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setRefreshPosts } from '../../redux/controls';
import { Form, Button } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import AlertClass from '../../classes/AlertClass';
import FirebaseClass from '../../classes/FirebaseClass';
import 'react-toastify/dist/ReactToastify.css';
import './textForm.css';

import homeImgRef from '../../assets/images/homeRef.png';

export default function TextForm() {
  const { loading, home_page_paras, about_page_paras } = useSelector(
    (state) => state.controls
  );
  const [canPostHp1, setCanPostHp1] = useState(true);
  const [canPostHp2, setCanPostHp2] = useState(true);
  const [canPostHt1, setCanPostHt1] = useState(true);
  const [canPostHt2, setCanPostHt2] = useState(true);
  const [canPostHt3, setCanPostHt3] = useState(true);
  const [canPostAp1, setCanPostAp1] = useState(true);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const ac = new AlertClass();
  const fb = new FirebaseClass();
  const formRef = useRef(null);

  const findFormErrors = () => {
    const newErrors = {};
    if (formRef.current['page'].value === 'Which page is this for?') {
      newErrors.useAtPage = 'This field is required.';
    }
    if (
      formRef.current['page-parag'].value === 'Which paragraph is this for?'
    ) {
      newErrors.pageParag = 'This field is required.';
    }
    return newErrors;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    e.preventDefault();
    const newErrors = findFormErrors();
    let canPost = true;
    let pg;
    let para;
    const txtOnly = true;
    const txtContent = formRef.current['content'].value;
    const page = formRef.current['page'].value;
    const pageParag = formRef.current['page-parag'].value;

    switch (page) {
      case 'home_page':
        pg = 'Home';
        break;
      case 'about_page':
        pg = 'About';
        break;
      default:
        // handle other cases if necessary
        break;
    }

    switch (pageParag) {
      case 'home_parag_one':
        para = 'paragraph one';
        break;
      case 'home_parag_two':
        para = 'paragraph two';
        break;
      case 'home_title_one':
        para = 'title one';
        break;
      case 'home_title_two':
        para = 'title two';
        break;
      case 'home_title_three':
        para = 'title three';
        break;
      case 'about_parag_one':
        para = 'paragraph';
        break;
      default:
        // handle other cases if necessary
        break;
    }

    const title = `${pg}: ${para}`;
    const cantPostError = `You can't post to ${title} because it already has content. Please edit the existing content or delete it first.`;

    switch (title) {
      case 'Home: paragraph one':
        if (canPostHp1 === false) {
          canPost = false;
          ac.errorAlert(cantPostError);
        }
        break;
      case 'Home: paragraph two':
        if (canPostHp2 === false) {
          canPost = false;
          ac.errorAlert(cantPostError);
        }
        break;
      case 'Home: title one':
        if (canPostHt1 === false) {
          canPost = false;
          ac.errorAlert(cantPostError);
        }
        break;
      case 'Home: title two':
        if (canPostHt2 === false) {
          canPost = false;
          ac.errorAlert(cantPostError);
        }
        break;
      case 'Home: title three':
        if (canPostHt3 === false) {
          canPost = false;
          ac.errorAlert(cantPostError);
        }
        break;
      case 'About: paragraph':
        if (canPostAp1 === false) {
          canPost = false;
          ac.errorAlert(cantPostError);
        }
        break;
      default:
        // handle other cases if necessary
        break;
    }

    if (canPost) {
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        makePost(title, txtContent, null, page, pageParag, txtOnly);
      }
    }
    formRef.current.reset();
  }

  async function makePost(title, txtContent, images, page, pageParag, txtOnly) {
    try {
      dispatch(setLoading(true));
      await fb.makePost(title, txtContent, images, page, pageParag, txtOnly);
      dispatch(setLoading(false));
      ac.successAlert('Post submitted!');
      dispatch(setRefreshPosts(true));
    } catch (error) {
      ac.errorAlert('Error submitting post', error);
    }
  }

  useEffect(() => {
    if (home_page_paras) {
      home_page_paras.forEach((pg) => {
        // console.log('pg: ', pg);
        switch (pg.page_parag) {
          case 'home_parag_one':
            setCanPostHp1(false);
            break;
          case 'home_parag_two':
            setCanPostHp2(false);
            break;
          case 'home_title_one':
            setCanPostHt1(false);
            break;
          case 'home_title_two':
            setCanPostHt2(false);
            break;
          case 'home_title_three':
            setCanPostHt3(false);
            break;
          default:
            // handle other cases if necessary
            break;
        }
      });
    }
    if (about_page_paras) {
      setCanPostAp1(false);
    }
    // console.log('home_page_paras: ', home_page_paras);
    // console.log('about_page_paras: ', about_page_paras);
  }, [home_page_paras, about_page_paras]);

  // console.log('canPostHp1: ', canPostHp1);
  // console.log('canPostHp2: ', canPostHp2);
  // console.log('canPostAp1: ', canPostAp1);

  return (
    <>
      <div className="text-form-container">
        <Form className="text-form" ref={formRef}>
          <Form.Group className="mb-3" controlId="content">
            <Form.Control as="textarea" rows="6" placeholder="Content" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="page">
            <Form.Control
              as="select"
              aria-label="Default select example"
              required
              isInvalid={!!errors.useAtPage}
              errors={errors.useAtPage}
              onChange={() => setErrors({})}>
              <option>Which page is this for?</option>
              <option value="home_page">Home Page</option>
              <option value="about_page">About Page</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select which page this is for.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="page-parag">
            <Form.Control
              as="select"
              aria-label="Default select example"
              required
              isInvalid={!!errors.pageParag}
              errors={errors.pageParag}
              onChange={() => setErrors({})}>
              <option>Which paragraph/title on the page?</option>
              <option value="" disabled="disabled">
                ─────────────────────────
              </option>

              <option value="home_parag_one">Home: paragraph one</option>
              <option value="home_parag_two">Home: paragraph two</option>
              <option value="home_title_one">Home: title one</option>
              <option value="home_title_two">Home: title two</option>
              <option value="home_title_three">Home: title three</option>
              <option value="" disabled="disabled">
                ─────────────────────────
              </option>

              <option value="about_parag_one">About: paragraph</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select which paragraph on the page this is for.
            </Form.Control.Feedback>
          </Form.Group>

          <div className="upload-button-wrapper">
            <Button
              className="upload-submit-button"
              type="submit"
              disabled={loading}
              onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </Form>

        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>

      <div className="home-ref-cont">
        <span className="home-ref-txt">Refernce for Home page</span>
        <img className="home-ref-img" src={homeImgRef} alt="home-ref-img" />
      </div>
    </>
  );
}
