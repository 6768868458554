import React, { useState } from 'react';
import { Card, Carousel } from 'react-bootstrap';
import GlobalClass from '../../classes/GlobalClass';

import '../../styles/global.css';
import './contentBox.css';

export default function ContentBox({
  id,
  title,
  description,
  images,
  price,
  handleModalToggle,
  mode,
}) {
  const gc = new GlobalClass();
  const LargeBox = () => {
    return (
      <div className="container-fluid cont-box-container" id={id}>
        <div className="row cont-box-row">
          <div className="col cont-box-col">
            <h2 className="cont-title">{title}</h2>
          </div>
          <div className="col cont-box-col">
            {price && (
              <span className="cont-price">${gc.addCommas(price)}</span>
            )}
          </div>
        </div>
        <div className="row cont-box-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 cont-box-col cont-text-col">
            {description && <p className="cont-description">{description}</p>}
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 cont-box-col cont-img-col">
            <div
              className="cb-img-area"
              onClick={() => handleModalToggle('view-only', id)}>
              {images &&
                images.map((image, index) => {
                  return (
                    <div className="cont-img-wrapper" key={index}>
                      <img
                        className="cont-img"
                        src={image}
                        alt="content-box-img bx-shdw"
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const SmallBox = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };

    return (
      <Card style={{ width: '18rem', margin: '1rem' }}>
        {images && images.length > 1 ? (
          <Carousel activeIndex={index} onSelect={handleSelect}>
            {images &&
              images.map((image, index) => {
                return (
                  <Carousel.Item key={index}>
                    <Card.Img variant="top" src={image} key={index} />
                  </Carousel.Item>
                );
              })}
          </Carousel>
        ) : (
          <Card.Img variant="top" src={images[0]} />
        )}

        <Card.Body>
          <Card.Title>{title}</Card.Title>
          {price && (
            <Card.Subtitle className="mb-2 text-muted">
              ${gc.addCommas(price)}
            </Card.Subtitle>
          )}
          {description && <Card.Text>{description}</Card.Text>}
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {mode === 'used_equipment' && <LargeBox />}
      {(mode === 'grinder_parts' ||
        mode === 'industrial_parts' ||
        mode === 'clutch_parts' ||
        mode === 'conveyor_and_drive_belts' ||
        mode === 'drag_and_drive_chains') && <SmallBox />}
    </>
  );
}
