import React, { useState } from 'react';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { Button } from 'react-bootstrap';

import '../../styles/global.css';
import './loginSignupForm.css';

export default function LoginSignupForm({ mode }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setpasswordConfirm] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  function checkForErrors() {
    if (!email || email === '') {
      setHasError(true);
      alert('Must provide a valid email address');
      resetForm();
    } else if (!email.includes('@')) {
      setHasError(true);
      alert('Email address must contain @ symbol');
      resetForm();
    } else if (!password || password === '') {
      setHasError(true);
      alert('Must provide a password');
      resetForm();
    }
  }

  function signIn(email, password) {
    checkForErrors();
    if (!hasError) {
      return signInWithEmailAndPassword(auth, email, password);
    } else {
      resetForm();
    }
  }

  function resetForm() {
    setEmail('');
    setPassword('');
    setHasError(false);
  }

  async function handleLogin(e) {
    e.preventDefault();
    try {
      setLoading(true);
      await signIn(email, password)
        .then(setLoading(false))
        .then(() => navigate('/'));
    } catch (error) {
      console.log(error.message);
      alert('Error', error);
    }
  }

  async function handleSignup(e) {
    e.preventDefault();
    if (password === passwordConfirm) {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log('user', user);
          alert('Account created successfully.');
        })
        .then(() => navigate('/'))
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log('errorCode', errorCode);
          console.log('errorMessage', errorMessage);
        });
    }
  }

  console.log('ERROR PRESENT:', hasError);

  const handleResetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(
        alert(
          'An email with a password reset has been sent. You may need to check the Spam folder.'
        )
      )
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <form className="login-signup-form">
      <input
        className="lsfrm-input"
        type="text"
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        className="lsfrm-input"
        type="password"
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
      />
      {mode === 'signup' && (
        <input
          className="lsfrm-input"
          type="password"
          placeholder="Confirm Password"
          onChange={(e) => setpasswordConfirm(e.target.value)}
        />
      )}

      {mode === 'login' && (
        <div className="lsfrm-btn-container">
          <Button
            type="submit"
            className="btn login-btn"
            disabled={loading}
            onClick={(e) => {
              handleLogin(e);
            }}>
            Login
          </Button>

          <span className="forgot-pass-link" onClick={handleResetPassword}>
            Forgot Password?
          </span>
        </div>
      )}

      {mode === 'signup' && (
        <Button
          type="submit"
          className="btn signup-btn"
          disabled={loading}
          onClick={(e) => {
            handleSignup(e);
          }}>
          Sign Up
        </Button>
      )}
    </form>
  );
}
