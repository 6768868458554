import React from 'react';
import SideMenu from '../../components/Navigation/SideMenu/SideMenu';
import LoginSignupForm from '../../components/LoginSignupForm/LoginForm';

import './signup.css';
import '../../styles/global.css';

export default function Signup() {
  return (
    <div className="container-fluid glbl-container">
      <SideMenu />
      <div className="glbl-content-container"></div>
      <LoginSignupForm mode="signup" />
    </div>
  );
}
