import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setRefreshPosts } from '../../redux/controls';
import { Button, Modal, Carousel } from 'react-bootstrap';
import FirebaseClass from '../../classes/FirebaseClass';
import './modalWindow.css';

import trashIcon from '../../assets/icons/trash.svg';

export default function ModalWindow({
  modalMode,
  show,
  setShow,
  setContinueDelete,
  post,
  setSelectedPost,
  setSelectedPostId,
}) {
  const dispatch = useDispatch();

  const fb = new FirebaseClass();
  const handleContinueDeleteThenClose = () => {
    setContinueDelete(true);
    setShow(false);
  };
  // console.log('selectedImage: ', selectedImage);

  const ConfirmModal = ({ show, setShow, message }) => {
    return (
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <span className="warning-text">WARNING</span>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <>
            <Button variant="primary" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button variant="warning" onClick={handleContinueDeleteThenClose}>
              Continue
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    );
  };

  const EditPostModal = ({ post, key }) => {
    const [postId, setPostId] = useState('');
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState('');
    const [desc, setDesc] = useState('');
    const [images, setImages] = useState([]);
    const imgRef = useRef(null);

    const handleUpdate = () => {
      const arrayFiles = Array.from(imgRef.current.files);
      try {
        if (arrayFiles.length > 0) {
          async function makeTheUpdate() {
            console.log('arrayFiles: ', arrayFiles);
            await fb.addNewPostImages(postId, images, arrayFiles);
          }
          makeTheUpdate();
          if (
            title !== undefined &&
            desc !== undefined &&
            price !== undefined
          ) {
            fb.updatePost(postId, title, desc, price);
          }
        } else {
          fb.updatePost(postId, title, desc, price);
        }
      } catch (error) {
        console.log('error updating post: ', error);
      }
    };

    const handleContinueUpdatePostThenClose = () => {
      handleUpdate();
      setPostId('');
      setTitle('');
      setPrice('');
      setDesc('');
      setImages([]);
      setShow(false);
      imgRef.current.value = null;
      dispatch(setRefreshPosts(true));
    };

    async function handleDeleteImage(path, index, postId) {
      const imgLinkInPostObj = images[index];
      const nameOfImgToDel = await fb.getImageNameFromUrl(imgLinkInPostObj);
      try {
        const success = fb.deletePostImage(
          path,
          nameOfImgToDel,
          postId,
          imgLinkInPostObj
        );
        if (success) {
          dispatch(setRefreshPosts(true));
          setPostId('');
          setTitle('');
          setPrice('');
          setDesc('');
          setImages([]);
          setShow(false);
          imgRef.current.value = null;
          setSelectedPostId(null);
          setSelectedPost(null);
        }
      } catch (error) {
        console.log('error deleting image: ', error);
      }
    }

    useEffect(() => {
      post &&
        post.forEach((post) => {
          setPostId(post.id);
          setTitle(post.title);
          setPrice(post.price);
          setDesc(post.txt_cont);
          setImages(post.images);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [post]);

    return (
      <Modal show={show} onHide={() => setShow(false)} key={postId}>
        <Modal.Header closeButton>
          <span className="activity-text">Edit Post</span>
        </Modal.Header>

        <Modal.Body>
          <div className="edit-post-modal-container">
            <div className="edit-post-modal-header">
              <label className="edit-post-modal-label">Title:</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="edit-post-modal-input"
              />
            </div>
            <div className="edit-post-modal-header">
              <label className="edit-post-modal-label">Price:</label>
              <input
                type="text"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className="edit-post-modal-input"
              />
            </div>
            <div className="edit-post-modal-body">
              <div className="edit-post-modal-text-container">
                <label className="edit-post-modal-label">Description:</label>
                <textarea
                  as="textarea"
                  rows="4"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  className="edit-post-modal-input"
                />
              </div>
              <div className="edit-post-modal-img-area">
                {images &&
                  images.map((img, index) => (
                    <div className="edit-post-modal-img-container" key={index}>
                      <img
                        className="edit-post-modal-img"
                        key={index}
                        src={img}
                        alt="post-img"
                      />
                      <img
                        src={trashIcon}
                        key={index + 'trash'}
                        alt="trash icon"
                        className="edit-post-modal-icon"
                        onClick={() =>
                          handleDeleteImage(postId, index, postId, images)
                        }
                      />
                    </div>
                  ))}
              </div>
              <input
                id="modal-file-input"
                type="file"
                multiple
                placeholder="Choose image file(s)"
                className="form-control file-selection-input"
                ref={imgRef}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <>
            <Button variant="primary" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button
              variant="warning"
              onClick={handleContinueUpdatePostThenClose}>
              Update
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    );
  };

  const ViewerModal = ({ show, setShow }) => {
    const [images, setImages] = useState([]);
    const [title, setTitle] = useState('');
    const [index, setIndex] = useState(0);

    useEffect(() => {
      post &&
        post.forEach((post) => {
          setImages(post.images);
          setTitle(post.title);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [post]);

    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };

    return (
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>{title}</Modal.Header>
        <Modal.Body>
          <Carousel activeIndex={index} onSelect={handleSelect}>
            {images &&
              images.map((image, index) => {
                return (
                  <Carousel.Item key={index}>
                    <img
                      className="modal-img-viewer-only"
                      src={image}
                      alt="modal"
                    />
                  </Carousel.Item>
                );
              })}
          </Carousel>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      {modalMode === 'confirm-required' && (
        <ConfirmModal
          modalMode={modalMode}
          show={show}
          setShow={setShow}
          message="Are you sure you want to delete this post? This action cannot be undone."
        />
      )}
      {modalMode === 'edit-post' && (
        <EditPostModal
          modalMode={modalMode}
          show={show}
          setShow={setShow}
          post={post}
          key={post.id}
        />
      )}
      {modalMode === 'view-only' && (
        <ViewerModal
          modalMode={modalMode}
          show={show}
          setShow={setShow}
          message="Are you sure you want to delete this post? This action cannot be undone."
        />
      )}
    </>
  );
}
