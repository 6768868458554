import React from 'react';
import { useSelector } from 'react-redux';
import { useFilteredPosts } from '../../hooks/usePosts';
import { useViewPostModal } from '../../hooks/useViewPostModal';
import SideMenu from '../../components/Navigation/SideMenu/SideMenu';
import Footer from '../../components/Footer/Footer';
import ContactCard from '../../components/ContactCard/ContactCard';
import PostWithModal from '../../components/PostWithModal/PostWithModal';

import './usedEquipment.css';
import '../../styles/global.css';

import { USED_EQUIP_PAGE as cont } from '../../assets/textContent/USED_EQUIP_PAGE';
import backgroundImg from '../../assets/images/bannerPlaceholder.jpg';

export default function UsedEquipment() {
  const { posts, show_modal, modal_mode } = useSelector(
    (state) => state.controls
  );
  const filteredPosts = useFilteredPosts(posts, 'used_equipment');
  const { handleModalToggle, selectedPost, selectedPostId } =
    useViewPostModal();

  return (
    <main className="container-fluid glbl-container">
      <SideMenu />
      <div className="glbl-content-container">
        <h1 className="glbl-h1">Used Equipment</h1>
        <section className="ue-txt-section">
          <span className="ue-txt">{cont.paraOne}</span>
          <div className="ue-row mt-twenty">
            <div className="ue-contact-container">
              <ContactCard fullWidth={true} />
              <img
                src={backgroundImg}
                alt="construction"
                className="ue-bg-img"
              />
            </div>
          </div>
        </section>
        <PostWithModal
          posts={filteredPosts}
          handleModalToggle={handleModalToggle}
          modalMode={modal_mode}
          show_modal={show_modal}
          selectedPost={selectedPost}
          selectedPostId={selectedPostId}
          mode={'used_equipment'}
        />
      </div>
      <Footer />
    </main>
  );
}
