import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import useWindowSize from '../../hooks/useWindowSize';
import SideMenu from '../../components/Navigation/SideMenu/SideMenu';
import Banner from '../../components/Banner/Banner';
import Footer from '../../components/Footer/Footer';
// import ImageCarousel from '../../components/ImageCarousel/ImageCarousel';
import ItemListMenu from '../../components/ItemListMenu/ItemListMenu';

import '../../styles/global.css';
import './home.css';

import { HOME_PAGE as defaultCont } from '../../assets/textContent/HOME_PAGE';
import { LINKS as link } from '../../assets/links/LINKS';
import petersonGrinder from '../../assets/images/peterson-grinder.jpeg';
import diamondZImg from '../../assets/images/diamond-z.jpg';

const defaultItems = [
  { name: 'Used Equipment', link: link.used_equipment },
  { name: 'New/Used Grinder & Chipper Parts', link: link.grinder_parts },
  { name: 'Industrial Parts', link: link.industrial_parts },
  { name: 'Clutch Parts', link: link.clutch_parts },
  { name: 'Conveyor & Drive Belts', link: link.conveyor_and_drive_belts },
  { name: 'Drag Chain & Drive Chain', link: link.drag_and_drive_chains },
];

const RowOne = ({ p1 }) => {
  return (
    <div className="row home-row hr-one mt-ten-percent">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 home-col home-text-col">
        <ItemListMenu items={defaultItems} />
        {/* <div className="home-para-wrapper hpw-one">{p1}</div> */}
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 home-col home-img-col">
        <img
          className="home-img hi-one bx-shdw"
          src={diamondZImg}
          alt="diamond z"
        />
      </div>
    </div>
  );
};

const RowTwo = ({ p2, p3 }) => {
  // const imgArr = [petersonGrinder];
  return (
    <div className="row home-row hr-two">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 home-col home-img-col">
        {/* <ImageCarousel givenImages={imgArr} /> */}
        <img
          className="home-img hi-two"
          src={petersonGrinder}
          alt="peterson grinder"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 home-col home-text-col">
        <div className="home-para-wrapper">
          <ul className="glbl-ul">
            <li className="glbl-bold">Services</li>
            <li className="ml-10">Equipment Inspections (US & Canada)</li>
            <li className="ml-10">Repair Estimates</li>
            <li className="ml-10">Insurance Estimates</li>
            <li className="ml-10">In-Shop Service & Rebuilds</li>
            <li className="ml-10">Onsite Service</li>
            <li className="ml-10">Safety & Maintenance Instruction</li>
          </ul>
          <br />
          <span>{p2}</span>
          <br />
          <br />
          <span>{defaultCont.paraFive}</span>
        </div>
      </div>
    </div>
  );
};

export default function Home() {
  const { home_page_paras } = useSelector((state) => state.controls);
  const [paraOne, setParaOne] = useState(defaultCont.paraOne);
  const [paraTwo, setParaTwo] = useState(defaultCont.paraFour);
  const [titleOne, setTitleOne] = useState('Used Equipment');
  const [titleTwo, setTitleTwo] = useState('New/Used Grinder & Chipper Parts');
  const [titleThree, setTitleThree] = useState('Industrial Parts');
  const [linkOne, setLinkOne] = useState('/used-equipment');
  const [linkTwo, setLinkTwo] = useState('/grinder-parts');
  const [linkThree, setLinkThree] = useState('/industrial-parts');
  // const winSize = useWindowSize();
  // console.log(winSize);

  useEffect(() => {
    if (home_page_paras) {
      home_page_paras.forEach((item) => {
        if (item.page_parag === 'home_parag_one') {
          setParaOne(item.txt_cont);
        }
        if (item.page_parag === 'home_parag_two') {
          setParaTwo(item.txt_cont);
        }
        if (item.page_parag === 'home_title_one') {
          setTitleOne(item.txt_cont);
        }
        if (item.page_parag === 'home_title_two') {
          setTitleTwo(item.txt_cont);
        }
        if (item.page_parag === 'home_title_three') {
          setTitleThree(item.txt_cont);
        }
      });
    }
  }, [home_page_paras]);

  return (
    <main className="container-fluid glbl-container">
      <SideMenu />
      <div className="glbl-content-container">
        <Banner
          titleOne={titleOne}
          titleTwo={titleTwo}
          titleThree={titleThree}
          linkOne={linkOne}
          linkTwo={linkTwo}
          linkThree={linkThree}
        />
        <RowOne p1={paraOne} />
        <RowTwo p2={paraTwo} />
      </div>
      <Footer />
    </main>
  );
}
