import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore, collection, query, orderBy } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyC_esnxwVSYjSWRLKqQdohWosUbmmf9aiE',
  authDomain: 'erichar-e0a17.firebaseapp.com',
  projectId: 'erichar-e0a17',
  storageBucket: 'erichar-e0a17.appspot.com',
  messagingSenderId: '383489655629',
  appId: '1:383489655629:web:d7ea72a6f76d8618dc2d04',
};

// init firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage(app);
// init services
export const db = getFirestore();
// references
export const postsRef = collection(db, 'posts');

// reference queries
export const postsRefQuery = query(
  collection(db, 'posts'),
  orderBy('createdAt', 'desc')
);
