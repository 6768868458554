export const LINKS = {
  home: '/',
  login: '/login',
  signup: '/signup',
  about: '/about',
  contact: '/contact',
  admin: '/admin',
  products_services: '/products-services',
  used_equipment: '/used-equipment',
  industrial_parts: '/industrial-parts',
  grinder_parts: '/grinder-parts',
  clutch_parts: '/clutch-parts',
  conveyor_and_drive_belts: '/conveyor-and-drive-belts',
  drag_and_drive_chains: '/drag-and-drive-chains',
  no_link_available: '/#',
};
