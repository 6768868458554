import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setRefreshPosts,
  setShowModal,
  setModalMode,
} from '../../redux/controls';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import UploadForm from '../UploadForm/UploadForm';
import TextForm from '../TextForm/TextForm';
import FooterControls from '../FooterControls/FooterControls';
import PostViewer from './PostViewer';
import ModalWindow from '../Modal/ModalWindow';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import GlobalClass from '../../classes/GlobalClass';
import FirebaseClass from '../../classes/FirebaseClass';
import './adminControlAndView.css';

export default function AdminControlAndView() {
  const { posts, refreshPosts, loading, show_modal, modal_mode } = useSelector(
    (state) => state.controls
  );
  const dispatch = useDispatch();
  const [adminActivity, setAdminActivity] = useState('manage_upload_posts');
  const [activityTitle, setActivityTitle] = useState('');
  const [continueDelete, setContinueDelete] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [tag, setTag] = useState('used_equipment');
  const [useUploadForm, setUseUploadForm] = useState(false);
  const gc = new GlobalClass();
  const fb = new FirebaseClass();
  const postLen = new GlobalClass();

  if (posts) {
    postLen.setLength(posts.length);
  }

  const handleSetAdminActivity = (e) => {
    setAdminActivity(e);
  };

  const filterPosts = (postId) => {
    if (posts) {
      const post = posts.filter((post) => post.id === postId);
      return post;
    }
    gc.errorAlert('Error: post not found');
    return null;
  };

  const handleModalToggle = (modalMode, postId) => {
    dispatch(setShowModal(!show_modal));
    dispatch(setModalMode(modalMode));
    if (postId) {
      setSelectedPostId(postId);
      setSelectedPost(filterPosts(postId));
    }
  };

  // for when admin activity changes
  useEffect(() => {
    switch (adminActivity) {
      case 'manage_upload_posts':
        setActivityTitle('Manage Used Equipment');
        setTag('used_equipment');
        break;
      case 'manage_grinder_parts':
        setActivityTitle('Manage Grinder Parts');
        setTag('grinder_parts');
        break;
      case 'manage_industrial_parts':
        setActivityTitle('Manage Industrial Parts');
        setTag('industrial_parts');
        break;
      case 'manage_drag_and_drive_chains':
        setActivityTitle('Manage Drag & Drive Chains');
        setTag('drag_and_drive_chains');
        break;
      case 'manage_conveyor_and_drive_belts':
        setActivityTitle('Manage Conveyor & Drive Belts');
        setTag('conveyor_and_drive_belts');
        break;
      case 'manage_clutch_parts':
        setActivityTitle('Manage Clutch Parts');
        setTag('clutch_parts');
        break;
      case 'manage_page_text':
        setActivityTitle('Manage Page Text');
        break;
      case 'manage_footer_content':
        setActivityTitle('Manage Footer Content');
        break;
      default:
        setActivityTitle('');
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminActivity]);

  // for when user deletes a post
  useEffect(() => {
    if (continueDelete) {
      fb.deletePost('posts', selectedPostId)
        .then(() => {
          dispatch(setRefreshPosts(true));
        })
        .catch((err) => {
          console.log('error deleting post: ', err);
        });
    }
    setContinueDelete(false);
    dispatch(setRefreshPosts(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continueDelete]);

  useEffect(() => {
    if (
      adminActivity === 'manage_upload_posts' ||
      adminActivity === 'manage_grinder_parts' ||
      adminActivity === 'manage_industrial_parts' ||
      adminActivity === 'manage_clutch_parts' ||
      adminActivity === 'manage_conveyor_and_drive_belts' ||
      adminActivity === 'manage_drag_and_drive_chains'
    ) {
      setUseUploadForm(true);
    } else {
      setUseUploadForm(false);
    }
  }, [adminActivity]);

  return (
    <div className="container-fluid acv-container">
      <div className="row activity-row">
        <h2 className="activity-title">{activityTitle}</h2>
        <div className="acv-dropdown-container">
          <DropdownButton
            id="form-selection"
            title="Select What You Want To Do"
            onSelect={handleSetAdminActivity}>
            <Dropdown.Item eventKey="manage_upload_posts">
              Manage Used Equipment
            </Dropdown.Item>
            <Dropdown.Item eventKey="manage_grinder_parts">
              Manage Grinder Parts
            </Dropdown.Item>
            <Dropdown.Item eventKey="manage_industrial_parts">
              Manage Industrial Parts
            </Dropdown.Item>
            <Dropdown.Item eventKey="manage_clutch_parts">
              Manage Clutch Parts
            </Dropdown.Item>
            <Dropdown.Item eventKey="manage_conveyor_and_drive_belts">
              Manage Conveyor & Drive Belts
            </Dropdown.Item>
            <Dropdown.Item eventKey="manage_drag_and_drive_chains">
              Manage Drag & Drive Chains
            </Dropdown.Item>
            <Dropdown.Item eventKey="manage_page_text">
              Manage Page Text
            </Dropdown.Item>
            <Dropdown.Item eventKey="manage_footer_content">
              Manage Footer Content
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      <div className="row acv-row">
        <div className="acv-left col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 acv-col">
          {useUploadForm && <UploadForm tag={tag} />}
          {adminActivity === 'manage_page_text' && <TextForm />}
          {adminActivity === 'manage_footer_content' && <FooterControls />}
        </div>
        <div className="acv-right col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 acv-col">
          {(loading || refreshPosts) && <LoadingSpinner />}

          {posts &&
            posts
              .filter((post) => post.is_settings !== true)
              .map((post) => (
                <PostViewer
                  post={post}
                  key={post.id}
                  handleModalToggle={handleModalToggle}
                  adminActivity={adminActivity}
                  tag={tag}
                />
              ))}

          {!posts && <div className="no-posts">No posts to show</div>}
        </div>
      </div>
      <ModalWindow
        show={show_modal}
        setShow={handleModalToggle}
        modalMode={modal_mode}
        setContinueDelete={setContinueDelete}
        post={selectedPost}
        setSelectedPost={setSelectedPost}
        setSelectedPostId={setSelectedPostId}
      />
    </div>
  );
}
