import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { auth, postsRef } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { onSnapshot } from 'firebase/firestore';
import { setCurrentUser, setUserAuth } from './redux/user';
import {
  setPosts,
  setRefreshPosts,
  setHomePageParas,
  setAboutPageParas,
  setFooterTxtContent,
  setSettings,
} from './redux/controls';
import NavRoutes from './routes/NavRoutes';
import GlobalClass from './classes/GlobalClass';

function App() {
  const { refreshPosts } = useSelector((state) => state.controls);
  const dispatch = useDispatch();
  const currentUser = useAuth();
  const gc = new GlobalClass();

  function useAuth() {
    const [currentUser, setCurrentUser] = useState();

    useEffect(() => {
      const unsub = onAuthStateChanged(auth, (user) => setCurrentUser(user));

      return unsub;
    }, []);

    return currentUser;
  }

  const fetchPosts = async () => {
    let txtContent;
    let homeContent;
    let aboutContent;
    let footerContent;
    let settings;
    onSnapshot(postsRef, (snapshot) => {
      const results = snapshot.docs.map((doc) => {
        return { ...doc.data(), key: doc.id };
      });
      const sortedResults = gc.sortData(results);
      dispatch(setPosts(sortedResults));

      txtContent = sortedResults.filter((item) => item.only_text === true);
      homeContent = txtContent.filter((item) => item.page === 'home_page');
      aboutContent = txtContent.filter((item) => item.page === 'about_page');
      footerContent = txtContent.filter((item) => item.page === 'Footer');
      settings = sortedResults.filter((item) => item.is_settings === true);
      if (homeContent.length > 0) {
        dispatch(setHomePageParas(homeContent));
        dispatch(setHomePageParas(homeContent));
      }
      if (aboutContent.length > 0) {
        dispatch(setAboutPageParas(aboutContent));
      }
      if (settings.length > 0) {
        const footerSettings = settings.filter(
          (item) => item.settings_for === 'footer_settings'
        );
        const footerSettingsObj = footerSettings[0];
        const postId = footerSettingsObj.id;
        let newFooterSettings = JSON.parse(JSON.stringify(footerSettingsObj));
        newFooterSettings.settings[0].id = postId;
        const newFooterSetting = newFooterSettings.settings[0];
        dispatch(setSettings({ footer_settings: newFooterSetting }));

        if (footerContent.length > 0) {
          const footerContentObj = footerContent[0];
          // console.log('footerContentObj: ', footerContentObj);
          let newFooterContent = JSON.parse(JSON.stringify(footerContentObj));
          // console.log('newFooterContent: ', newFooterContent);
          const { id, txt_cont, page_parag } = newFooterContent;
          // console.log('id: ', id);
          // console.log('title: ', title);
          // console.log('txt_cont: ', txt_cont);
          // console.log('page: ', page);
          // console.log('page_parag: ', page_parag);

          const newFooterContentObj = {
            id: '',
            ftrTxtOne: 'Footer text one',
            ftrTxtTwo: 'Footer text two',
            ftrTxtThree: 'Footer text three',
            ftrTxtFour: 'Footer text four',
          };
          newFooterContentObj.id = id;
          if (page_parag === 'footer_txt_one') {
            newFooterContentObj.ftrTxtOne = txt_cont;
          }
          if (page_parag === 'footer_txt_two') {
            newFooterContentObj.ftrTxtTwo = txt_cont;
          }
          if (page_parag === 'footer_txt_three') {
            newFooterContentObj.ftrTxtThree = txt_cont;
          }
          if (page_parag === 'footer_txt_four') {
            newFooterContentObj.ftrTxtFour = txt_cont;
          }
          // console.log('newFooterContentObj: ', newFooterContentObj);
          dispatch(setFooterTxtContent(newFooterContentObj));
        }
      }
    });
  };

  useEffect(() => {
    if (currentUser) {
      dispatch(setCurrentUser(currentUser));
      dispatch(setUserAuth(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPosts();
    dispatch(setRefreshPosts(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPosts]);

  return (
    <div className="App">
      <BrowserRouter>
        <NavRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
