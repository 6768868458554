import React, { useState, useEffect } from 'react';
import './adminControlAndView.css';

import trashIcon from '../../assets/icons/trash.svg';
import editIcon from '../../assets/icons/edit.svg';

export default function PostViewer({
  post,
  handleModalToggle,
  adminActivity,
  tag,
}) {
  const [title, setTitle] = useState('');
  const [txtCont, setTxtCont] = useState('');
  const [price, setPrice] = useState('');
  const [onlyText, setOnlyText] = useState(false);
  const [imgs, setImgs] = useState([]);
  const [postId, setPostId] = useState('');
  const [postTag, setPostTag] = useState('');
  const [useGeneralViewer, setUseGeneralViewer] = useState(false);

  useEffect(() => {
    if (post) {
      if ((post.title, post.txt_cont, post.only_text, post.images, post.id)) {
        setTitle(post.title);
        setTxtCont(post.txt_cont);
        setOnlyText(post.only_text);
        setPostId(post.id);
        if (post.images) {
          const imgArr = [];
          post.images.forEach((img) => {
            imgArr.push(img);
          });
          setImgs(imgArr);
        }
      }
      if (post.price) {
        setPrice(post.price);
      }
      if (post.tag) {
        setPostTag(post.tag);
      }
    }
  }, [post]);

  const TextPostViewer = () => (
    <div className="post-viewer" key={postId}>
      <div className="post-header">
        <h2 className="pg-para-title">{title}</h2>
        <div className="post-options-container">
          <img
            src={editIcon}
            alt="trash icon"
            className="acv-edit-icon"
            onClick={() => handleModalToggle('edit-post', postId)}
          />
          <img
            src={trashIcon}
            alt="trash icon"
            className="acv-trash-icon"
            key={postId}
            onClick={() => handleModalToggle('confirm-required', postId)}
          />
        </div>
      </div>
      <div className="post-body">
        <div className="post-text-container">
          <span className="description">{txtCont}</span>
        </div>
      </div>
    </div>
  );

  const GeneralPostViewer = () => (
    <div className="post-viewer" key={postId}>
      <div className="post-header">
        <h2 className={onlyText ? 'pg-para-title' : 'post-title'}>{title}</h2>
        <div className="post-options-container">
          <img
            src={editIcon}
            alt="trash icon"
            className="acv-edit-icon"
            onClick={() => handleModalToggle('edit-post', postId)}
          />
          <img
            src={trashIcon}
            alt="trash icon"
            className="acv-trash-icon"
            key={postId}
            onClick={() => handleModalToggle('confirm-required', postId)}
          />
        </div>
      </div>
      <div className="post-body">
        <div className="post-text-container">
          <span className="price">${price}</span>
        </div>
        <hr className="post-divider" />
        <div className="post-text-container">
          <span className="description">{txtCont}</span>
        </div>
        <hr className="post-divider" />
        <div className="post-img-container">
          {imgs.map((img, index) => (
            <img className="post-img" key={index} src={img} alt="post-img" />
          ))}
        </div>
        <hr className="post-divider" />
        <div className="post-info">
          <div className="post-text-container">
            <span className="description">Number of images: {imgs.length}</span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {adminActivity !== 'manage_upload_posts' &&
        adminActivity !== 'manage_grinder_parts' &&
        adminActivity !== 'manage_industrial_parts' &&
        adminActivity !== 'manage_clutch_parts' &&
        adminActivity !== 'manage_conveyor_and_drive_belts' &&
        adminActivity !== 'manage_drag_and_drive_chains' &&
        onlyText && <TextPostViewer key={post.id} />}
      {adminActivity === 'manage_upload_posts' &&
        postTag === 'used_equipment' &&
        !onlyText && <GeneralPostViewer key={post.id} />}
      {adminActivity === 'manage_grinder_parts' &&
        postTag === 'grinder_parts' &&
        !onlyText && <GeneralPostViewer key={post.id} />}
      {adminActivity === 'manage_industrial_parts' &&
        postTag === 'industrial_parts' &&
        !onlyText && <GeneralPostViewer key={post.id} />}
      {adminActivity === 'manage_clutch_parts' &&
        postTag === 'clutch_parts' &&
        !onlyText && <GeneralPostViewer key={post.id} />}
      {adminActivity === 'manage_conveyor_and_drive_belts' &&
        postTag === 'conveyor_and_drive_belts' &&
        !onlyText && <GeneralPostViewer key={post.id} />}
      {adminActivity === 'manage_drag_and_drive_chains' &&
        postTag === 'drag_and_drive_chains' &&
        !onlyText && <GeneralPostViewer key={post.id} />}
    </>
  );
}
