import React from 'react';
import SideMenu from '../../components/Navigation/SideMenu/SideMenu';
import LoginSignupForm from '../../components/LoginSignupForm/LoginForm';

import './login.css';
import '../../styles/global.css';

export default function Login() {
  return (
    <main className="container-fluid glbl-container">
      <SideMenu />
      <div className="glbl-content-container">
        <div className="row login-page-row">
          <h1 className="glbl-h1">Login</h1>
        </div>
        <div className="login-signup-form-container">
          <LoginSignupForm mode="login" />
        </div>
      </div>
    </main>
  );
}
