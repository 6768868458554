import React from 'react';
import ContentBox from '../ContentBox/ContentBox';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ModalWindow from '../Modal/ModalWindow';

import '../../styles/global.css';
import './postWithModal.css';

export default function PostWithModal({
  posts,
  handleModalToggle,
  modalMode,
  selectedPost,
  selectedPostId,
  mode,
  show_modal,
}) {
  return (
    <>
      {posts ? (
        posts.map((post, index) => (
          <ContentBox
            key={index}
            id={post.id}
            title={post.title}
            description={post.txt_cont}
            price={post.price}
            images={post.images}
            handleModalToggle={handleModalToggle}
            mode={mode}
          />
        ))
      ) : (
        <LoadingSpinner />
      )}
      <ModalWindow
        show={show_modal}
        setShow={handleModalToggle}
        modalMode={modalMode}
        post={selectedPost}
        postId={selectedPostId}
      />
    </>
  );
}
