import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setRefreshPosts } from '../../redux/controls';
import { Form, Button } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import FooterTextForm from './FooterTextForm';
import AlertClass from '../../classes/AlertClass';
import FirebaseClass from '../../classes/FirebaseClass';
import 'react-toastify/dist/ReactToastify.css';
import './footerControls.css';

import footerImgRef from '../../assets/images/footerImgRef.png';

export default function FooterControls() {
  const { loading, settings } = useSelector((state) => state.controls);
  const [selectedFooterCtrl, setSelectedFooterCtrl] =
    useState('footer_txt_content');
  const [facebookLink, setFacebookLink] = useState('');
  const [twitterLink, setTwitterLink] = useState('');
  const [instagramLink, setInstagramLink] = useState('');
  const [linkedinLink, setLinkedinLink] = useState('');
  const [googleLink, setGoogleLink] = useState('');
  const [footerSettings, setFooterSettings] = useState({
    id: '',
    show_txt_one: true,
    show_txt_two: true,
    show_txt_three: true,
    show_txt_four: true,
    show_facebook_icon: true,
    show_twitter_icon: true,
    show_instagram_icon: true,
    show_linkedin_icon: true,
    show_google_icon: true,
    facebook_link: 'https://www.facebook.com',
    twitter_link: 'https://www.twitter.com',
    instagram_link: 'https://www.instagram.com',
    linkedin_link: 'https://www.linkedin.com',
    google_link: 'https://www.google.com',
  });
  const [footerSettingsPostId, setFooterSettingsPostId] = useState(null);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const ac = new AlertClass();
  const fb = new FirebaseClass();

  const { footer_settings } = settings;

  useEffect(() => {
    if (settings) {
      setFooterSettings(footer_settings);
      setFooterSettingsPostId(footer_settings.id);
    }
  }, [settings, footer_settings]);

  useEffect(() => {
    if (facebookLink !== '') {
      setFooterSettings((prevSettings) => ({
        ...prevSettings,
        facebook_link: facebookLink,
      }));
    }
    if (twitterLink !== '') {
      setFooterSettings((prevSettings) => ({
        ...prevSettings,
        twitter_link: twitterLink,
      }));
    }
    if (instagramLink !== '') {
      setFooterSettings((prevSettings) => ({
        ...prevSettings,
        instagram_link: instagramLink,
      }));
    }
    if (linkedinLink !== '') {
      setFooterSettings((prevSettings) => ({
        ...prevSettings,
        linkedin_link: linkedinLink,
      }));
    }
    if (googleLink !== '') {
      setFooterSettings((prevSettings) => ({
        ...prevSettings,
        google_link: googleLink,
      }));
    }
  }, [facebookLink, twitterLink, instagramLink, linkedinLink, googleLink]);

  const handleSetFooterSettings = (key, value) => {
    setFooterSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
  };

  async function makeSettingsPost(isSettings, settingsFor, settings) {
    try {
      dispatch(setLoading(true));
      if (footerSettingsPostId === '') {
        await fb.makeSettingsPost(isSettings, settingsFor, settings);
      } else {
        await fb.updateSettingsPost(
          footerSettingsPostId,
          isSettings,
          settingsFor,
          settings
        );
      }
      dispatch(setLoading(false));
      ac.successAlert('Submitted!');
      dispatch(setRefreshPosts(true));
    } catch (error) {
      ac.errorAlert('Error submitting post', error);
    }
  }

  async function handleSubmitSettingsPost(e) {
    e.preventDefault();

    const isSettings = true;
    const settingsFor = 'footer_settings';
    const settings = [footerSettings];
    makeSettingsPost(isSettings, settingsFor, settings);
  }

  const FooterControlSelector = () => {
    return (
      <Form className="footer-form">
        <Form.Group
          className="mb-3 ftr-ctrl-selector"
          controlId="footer-ctrl-selected">
          <Form.Control
            as="select"
            aria-label="Default select example"
            required
            onChange={(e) => setSelectedFooterCtrl(e.target.value)}>
            <option>Which Footer Control do you want to manage?</option>
            <option value="footer_txt_content">Footer Text Content</option>
            <option value="footer_settings">Footer Display Settings</option>
          </Form.Control>
        </Form.Group>
      </Form>
    );
  };

  const FooterSettingsForm = () => {
    return (
      <Form className="footer-form" ref={FooterSettingsForm}>
        <Form.Group className="mb-3" controlId="facebook-link">
          <Form.Label>Facebook Link</Form.Label>
          <Form.Control
            type="text"
            placeholder={footerSettings.facebook_link}
            isInvalid={!!errors.facebook_link}
            errors={errors.facebook_link}
            onChange={(e) => setFacebookLink(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="twitter-link">
          <Form.Label>Twitter Link</Form.Label>
          <Form.Control
            type="text"
            placeholder={footerSettings.twitter_link}
            isInvalid={!!errors.twitter_link}
            errors={errors.twitter_link}
            onChange={(e) => setTwitterLink(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="instagram-link">
          <Form.Label>Instagram Link</Form.Label>
          <Form.Control
            type="text"
            placeholder={footerSettings.instagram_link}
            isInvalid={!!errors.instagram_link}
            errors={errors.instagram_link}
            onChange={(e) => setInstagramLink(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="linkedin-link">
          <Form.Label>LinkedIn Link</Form.Label>
          <Form.Control
            type="text"
            placeholder={footerSettings.linkedin_link}
            isInvalid={!!errors.linkedin_link}
            errors={errors.linkedin_link}
            onChange={(e) => setLinkedinLink(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="google-link">
          <Form.Label>Google Link</Form.Label>
          <Form.Control
            type="text"
            placeholder={footerSettings.google_link}
            isInvalid={!!errors.google_link}
            errors={errors.google_link}
            onChange={(e) => setGoogleLink(e.target.value)}
          />
        </Form.Group>

        <Form.Check
          inline
          className=""
          label="Show Footer Text One"
          name="show_txt_one"
          id="show_txt_one"
          feedbackType="invalid"
          checked={footerSettings.show_txt_one}
          value={footerSettings.show_txt_one}
          isInvalid={!!errors.show_txt_one}
          feedback={errors.show_txt_one}
          onChange={() =>
            handleSetFooterSettings(
              'show_txt_one',
              !footerSettings.show_txt_one
            )
          }
        />

        <Form.Check
          inline
          className=""
          label="Show Footer Text Two"
          name="show_txt_two"
          id="show_txt_two"
          feedbackType="invalid"
          checked={footerSettings.show_txt_two}
          value={footerSettings.show_txt_two}
          isInvalid={!!errors.show_txt_two}
          feedback={errors.show_txt_two}
          onChange={() =>
            handleSetFooterSettings(
              'show_txt_two',
              !footerSettings.show_txt_two
            )
          }
        />

        <Form.Check
          inline
          className=""
          label="Show Footer Text Three"
          name="show_txt_three"
          id="show_txt_three"
          feedbackType="invalid"
          checked={footerSettings.show_txt_three}
          value={footerSettings.show_txt_three}
          isInvalid={!!errors.show_txt_three}
          feedback={errors.show_txt_three}
          onChange={() =>
            handleSetFooterSettings(
              'show_txt_three',
              !footerSettings.show_txt_three
            )
          }
        />

        <Form.Check
          inline
          className=""
          label="Show Footer Text Four"
          name="show_txt_four"
          id="show_txt_four"
          feedbackType="invalid"
          checked={footerSettings.show_txt_four}
          value={footerSettings.show_txt_four}
          isInvalid={!!errors.show_txt_four}
          feedback={errors.show_txt_four}
          onChange={() =>
            handleSetFooterSettings(
              'show_txt_four',
              !footerSettings.show_txt_four
            )
          }
        />

        <Form.Check
          inline
          className=""
          label="Show Facebook Icon"
          name="show_facebook_icon"
          id="show_facebook_icon"
          feedbackType="invalid"
          checked={footerSettings.show_facebook_icon}
          value={footerSettings.show_facebook_icon}
          isInvalid={!!errors.show_facebook_icon}
          feedback={errors.show_facebook_icon}
          onChange={() =>
            handleSetFooterSettings(
              'show_facebook_icon',
              !footerSettings.show_facebook_icon
            )
          }
        />

        <Form.Check
          inline
          className=""
          label="Show Twitter Icon"
          name="show_twitter_icon"
          id="show_twitter_icon"
          feedbackType="invalid"
          checked={footerSettings.show_twitter_icon}
          value={footerSettings.show_twitter_icon}
          isInvalid={!!errors.show_twitter_icon}
          feedback={errors.show_twitter_icon}
          onChange={() =>
            handleSetFooterSettings(
              'show_twitter_icon',
              !footerSettings.show_twitter_icon
            )
          }
        />

        <Form.Check
          inline
          className=""
          label="Show Instagram Icon"
          name="show_instagram_icon"
          id="show_instagram_icon"
          feedbackType="invalid"
          checked={footerSettings.show_instagram_icon}
          value={footerSettings.show_instagram_icon}
          isInvalid={!!errors.show_instagram_icon}
          feedback={errors.show_instagram_icon}
          onChange={() =>
            handleSetFooterSettings(
              'show_instagram_icon',
              !footerSettings.show_instagram_icon
            )
          }
        />

        <Form.Check
          inline
          className=""
          label="Show LinkedIn Icon"
          name="show_linkedin_icon"
          id="show_linkedin_icon"
          feedbackType="invalid"
          checked={footerSettings.show_linkedin_icon}
          value={footerSettings.show_linkedin_icon}
          isInvalid={!!errors.show_linkedin_icon}
          feedback={errors.show_linkedin_icon}
          onChange={() =>
            handleSetFooterSettings(
              'show_linkedin_icon',
              !footerSettings.show_linkedin_icon
            )
          }
        />

        <Form.Check
          inline
          className=""
          label="Show Google Icon"
          name="show_google_icon"
          id="show_google_icon"
          feedbackType="invalid"
          checked={footerSettings.show_google_icon}
          value={footerSettings.show_google_icon}
          isInvalid={!!errors.show_google_icon}
          feedback={errors.show_google_icon}
          onChange={() =>
            handleSetFooterSettings(
              'show_google_icon',
              !footerSettings.show_google_icon
            )
          }
        />

        <div className="upload-button-wrapper">
          <Button
            className="upload-submit-button"
            type="submit"
            disabled={loading}
            onClick={handleSubmitSettingsPost}>
            Submit
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <>
      <div className="footer-form-container">
        <FooterControlSelector />

        {selectedFooterCtrl === 'footer_txt_content' && <FooterTextForm />}
        {selectedFooterCtrl === 'footer_settings' && <FooterSettingsForm />}

        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>

      <div className="footer-ref-cont">
        <span className="footer-ref-txt">Refernce for Footer</span>
        <img
          className="footer-ref-img"
          src={footerImgRef}
          alt="footer-ref-img"
        />
      </div>
    </>
  );
}
